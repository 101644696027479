<template>
  <div class="center">
    <button @click.prevent="checkingInstall" type="button" class="btn btn-icon" title="Restore"><i class="fa fa-undo btn-icon-wrapper"></i></button>
    <button @click.prevent="onClickDelete" type="button" class="btn btn-icon" title="Delete"><i class="fa fa-trash btn-icon-wrapper text-danger"></i></button>
    
    <vuestic-modal
      :isOpen="isOpenModalRestrict"
      @cancel="isOpenModalRestrict = false"
      :cancelShown="true"
      :okShown="false"
      cancelText="Close"
      cancelClass="btn btn-primary"
    >
      <span slot="title" class="text-danger font-weight-bold">No Installs Available</span>
      <div class="d-block text-center">
        <span class="no-install-available-span">You don't have any installs available. If you would like to add a new install you must delete an existing install or upgrade your subscription to add additional installs. Please contact us at <a class="link" v-bind:href="'mailto:' + agencyOwnerEmail">{{ agencyOwnerEmail }}</a> If you would like to add an additional install.</span>
      </div>
    </vuestic-modal>
  </div>
</template>
<script>

export default {
  props: ['row', 'nested', 'xprops'],
  methods : {
    openRestoreModal() {
      this.xprops.eventbus.$emit('openRestoreModal', this.row);
    },

    checkingInstall(){
      this.$store.dispatch('business/getInstallStats')
        .then(res => {
          this.businessTotalInstall = res.data.total_installs
          this.businessAvailabeInstall = res.data.available_installs

          if (!this.businessAvailabeInstall) {
            this.isOpenModalRestrict = true
          } else {
            this.openRestoreModal();
          }
        })
    },

    onClickDelete() {
      this.xprops.eventbus.$emit('openDeleteModal', this.row);
    }
  },
  data(){
    return {
      businessTotalInstall: null,
      businessAvailabeInstall: null,
      isOpenModalRestrict: false,
    }
  },
  
  computed: {
    user () {
      return this.$store.state.auth.user
    },
    agencyOwnerEmail () {
      return this.user.agency && this.user.agency.email
    },
  }
}
</script>

<style scoped lang="scss">
  button {
    i {
      font-size: 1.6rem;
    }
  }
</style>